import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { UrlBuilder } from 'http-url-builder';
import { SingletonSecureStorageService } from './core/services/singleton-secure-storage.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthorizationService } from './core/services/authorization.service';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.clientid,
          authority: environment.authority,
          redirectUri: environment.redirecturl,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: environment.authScopes,
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          // This sets the Bearer Authorization
          ['https://graph.microsoft.com/v1.0', environment.authScopes],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.policyService)
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.documentsApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.workLoadApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.loanEngineApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.loanEngine)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.dashboardApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.dynamicModelApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.dynamicModelApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.rightmove)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.kyc)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.covenantsApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.vulnerabilitiesApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.groupExposureApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.customerExposureApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.customersApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.incomeAndExpenditureApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.payments)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.searchApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.middlewareApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [environment.blobStorage, environment.authScopes],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.communicationApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.restructure)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.demandsApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.emailApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.paragonAdaptorApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
          [
            new UrlBuilder(environment.apim.baseUrl)
              .addPath(environment.apim.apiSlugs.addressValidationApi)
              .addPathVariable('/')
              .build(),
            environment.authScopes,
          ],
        ]),
      }
    ),
  ],
  providers: [
    SingletonSecureStorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ss: SingletonSecureStorageService) => () => ss.init(),
      deps: [SingletonSecureStorageService],
      multi: true,
    },
    AuthorizationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (as: AuthorizationService) => () => as.checkAuth(),
      deps: [AuthorizationService],
      multi: true,
    },
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
